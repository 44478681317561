/**
 *
 * @param el HTMLElement
 * @param filter
 * @return HTMLElement|null
 */
export function queryNext(el, filter) {
    return querySibling(true, el, filter)
}

/**
 *
 * @param el HTMLElement
 * @param filter
 * @return HTMLElement|null
 */
export function queryPrevious(el, filter) {
    return querySibling(false, el, filter)
}

export function querySibling(isDirectionNext, el, filter) {
    const next = isDirectionNext ? el.nextElementSibling : el.previousElementSibling

    if (next && !next.matches(filter)) {
        return querySibling(isDirectionNext, next, filter)
    }

    return next
}