'use strict'

import unorm from 'unorm'
import debounce from 'lodash.debounce'
import {queryNext, queryPrevious} from './helper/dom.js'
import {collapse} from './helper/dom-bootstrap.js'

let searchInput
// todo ver se alguma lib já essas refs

const KEYS = {
    ESC: 'Escape',
    LEFT: 'ArrowLeft',
    UP: 'ArrowUp',
    RIGHT: 'ArrowRight',
    DOWN: 'ArrowDown',
    M: 'm',
}

let clientX, clientY

const ID_MENU_PRINCIPAL = 'main-menu'

function getUlDosFilhos(el) {
    return el.querySelector('ul')
}

function focarCampoBuscaMenu() {
    searchInput.focus()
}

const menu = {
    normalizar(str) {
        var normalized = unorm.nfd(str).replace(/[\u0300-\u036f]/g, '').toLowerCase()
        var regexp = new RegExp(/\s+/g)
        return normalized.replace(regexp, ' ').trim()
    },

    init: function() {
        searchInput = document.getElementById('sidebar-searchbox')
        const menuExiste = searchInput !== null
        if (!menuExiste) {
            return
        }

        function removerHighlights() {
            $('.menu-text-highlight').each(function() {
                var spanCorrespondente = $(this).parent('span')
                spanCorrespondente.text(spanCorrespondente.text())
            })
        }

        /**
         *
         * @param ul DomElement
         * @param termoBusca
         */
        function filtrar(ul, termoBusca) {
            let match = false

            ul.querySelectorAll('li').forEach(liAtual => {
                let matchEmFilho = false
                let ulDosFilhos
                const hasFilhos = menu.possuiFilhos(liAtual)

                if (hasFilhos) {
                    ulDosFilhos = getUlDosFilhos(liAtual)
                    if (filtrar(ulDosFilhos, termoBusca)) {
                        matchEmFilho = true
                    }
                }

                const liSearchTerm = getElementoComTexto(liAtual).textContent
                const matchLiAtual = menu.deuMatch(liSearchTerm, termoBusca)

                if (hasFilhos) {
                    collapse(ulDosFilhos, matchEmFilho)
                }

                if (matchLiAtual || matchEmFilho) {
                    if (matchLiAtual) {
                        match = true
                        realcarMatch(liAtual, termoBusca)
                    }

                    exibirContainer(liAtual)
                } else {
                    esconderContainer(liAtual)
                }
            })

            return match
        }

        function realcarMatch(li, filtro) {
            var spanText = getElementoComTexto(li)
            var textAtual = spanText.textContent
            var filtroProcessado = menu.normalizar(filtro)
            var termoBuscaItemMenuNormalizado = menu.normalizar(textAtual)
            var idx = termoBuscaItemMenuNormalizado.indexOf(filtroProcessado)
            var filterLength = filtroProcessado.length

            var preMatch = textAtual.substr(0, idx)
            var match = textAtual.substr(idx, filterLength)
            var posMatch = textAtual.substr(idx + filterLength, textAtual.length)
            spanText.innerHTML = preMatch
                + '<span class="menu-text-highlight">'
                + match
                + '</span>'
                + posMatch

        }

        function getElementoComTexto(el) {
            return el.querySelector('.menu-item-text')
        }

        function esconderContainer(el) {
            el.classList.add('d-none')
        }

        function exibirContainer(el) {
            el.classList.remove('d-none')
        }

        function getTipoViewport(width) {
            return width <= TAMANHO_MIN_DESKTOP ? TIPO_MOBILE : TIPO_DESKTOP
        }

        function resize() {
            var wrapper = document.getElementById('wrapper')
            var menuAbreAutomaticamente = wrapper.classList.contains('abre-automaticamente')

            var width = window.innerWidth
            var menuAberto = !wrapper.classList.contains('toggled')
            var tipoViewportNovo = getTipoViewport(width)

            if (tipoViewportNovo !== viewport) {
                viewport = tipoViewportNovo
                var isDesktop = (tipoViewportNovo === TIPO_DESKTOP)
                var isMobile = (tipoViewportNovo === TIPO_MOBILE)

                if (menuAberto && isMobile) {
                    contrairMenu()
                } else if (!menuAberto && isDesktop && menuAbreAutomaticamente) {
                    expandirMenu()
                }
            }
        }

        /**
         *
         * @param e KeyboardEvent
         */
        function handleBuscaMenu(e) {

            const input = e.target
            let termoBuscaNormalizado
            let termoBuscaAnterior = input.dataset.previousValue || ''

            if (e.key === KEYS.ESC) {
                termoBuscaNormalizado = ''
                input.value = ''
            } else {
                termoBuscaNormalizado = menu.normalizar(input.value)
            }

            if (termoBuscaNormalizado !== termoBuscaAnterior) {
                removerHighlights()
                input.dataset.previousValue = termoBuscaNormalizado

                const ulMenu = document.getElementById(ID_MENU_PRINCIPAL)
                if (termoBuscaNormalizado === '') {
                    document.querySelectorAll('#main-menu .collapse.show').forEach(el => collapse(el, false))
                } else {
                    filtrar(ulMenu, termoBuscaNormalizado)
                }
            }
        }

        var TAMANHO_MIN_DESKTOP = 768
        var TIPO_MOBILE = 0
        var TIPO_DESKTOP = 1
        var viewport = null

        resize()

        document.getElementById('menu-btn').addEventListener('click', function(e) {
            e.preventDefault()

            if (!document.getElementById('wrapper').classList.contains('touched')) {
                document.getElementById('wrapper').classList.add('touched')
            }

            if (isMenuAberto()) {
                menu.fechar()
            } else {
                menu.abrir()
            }
        })

        window.addEventListener('resize', resize)

        document.querySelectorAll(`#${ID_MENU_PRINCIPAL} ul.collapse`).forEach(el => {
            $(el).on('show.bs.collapse', (e) => {
                e.stopPropagation()
                e.target.querySelectorAll('li').forEach(el => exibirContainer(el))
            })
        })

        searchInput.addEventListener('keydown', debounce(handleBuscaMenu, 250))

        if (PaginaEproc.isTelaPequena()) {
            document.getElementById('page-content-wrapper').addEventListener('click touchmove', contrairMenu)
        }

        function acoesTeclaAtalhoMenu() {
            focarCampoBuscaMenu()
            expandirMenu()
        }

        $(document).bind('keydown', 'Alt+M', acoesTeclaAtalhoMenu)

        document.getElementById('menu-btn').addEventListener('click', function() {
            if (PaginaEproc.isTelaPequena()) {
                window.setTimeout(function() {
                    focarCampoBuscaMenu()
                }, 200)
            } else {
                document.getElementById('menu-btn').blur()
            }
        })

        searchInput.addEventListener('keydown', function(e) {
            if (e.key === KEYS.DOWN) { //down
                var primeiroVisivel = document.querySelector(`#${ID_MENU_PRINCIPAL} li:not(.d-none) > a`)
                primeiroVisivel.focus()
                e.preventDefault()
            }
        })
        document
            .querySelectorAll(`#${ID_MENU_PRINCIPAL} a`)
            .forEach(el => el.addEventListener(
                'keydown',
                handleKeydownItensMenu,
            ))

    },
    getItemApos: function(focusedLinkSelector) {
        var li = focusedLinkSelector.parentElement
        var hasSubmenu = this.possuiFilhos(li)
        var isItemCollapsed = this.isItemCollapsed(li)
        var nextLi = null
        var nextLink = null

        if (hasSubmenu && !isItemCollapsed) {
            nextLi = li.querySelector('ul.show li:not(.d-none)')
        } else {
            nextLi = this.getProximoLi(li)
        }

        if (nextLi) {
            nextLink = this.getItemLink(nextLi)
        }

        return nextLink
    },
    getItemAntesDe: function(focusedLinkSelector) {

        var aLi = focusedLinkSelector.parentElement
        var prevLi = queryPrevious(aLi, ':not(.d-none)')
        var prevLiA = null

        if (prevLi) {
            var prevLastVisibleLI = this.getUltimoFilhoVisivelDe(prevLi)
            prevLiA = this.getItemLink(prevLastVisibleLI)
        } else {
            var ul = aLi.parentElement
            var parentIsRoot = ul.id === ID_MENU_PRINCIPAL

            if (parentIsRoot) {
                return null
            } else {
                prevLiA = $(ul).prevAll('a').filter(':visible').first()[0]
            }
        }

        return prevLiA
    },
    getUltimoFilhoVisivelDe: function(li) {
        var hasSubmenu = this.possuiFilhos(li)
        var isItemCollapsed = this.isItemCollapsed(li)
        var lastChild = null

        if (hasSubmenu && !isItemCollapsed) {
            lastChild = Array.from(li.querySelectorAll('li:not(.d-none)')).pop()
        } else {
            lastChild = li
        }

        return lastChild
    },
    getLiPai: function(li) {
        var parentUl = li.parentElement
        var parentIsRoot = parentUl.id === ID_MENU_PRINCIPAL
        var parentLi = null

        if (!parentIsRoot) {
            parentLi = parentUl.parentElement
        }
        return parentLi

    },
    possuiFilhos(li) {
        return li.classList.contains('has-submenu')
    },
    getItemLink(li) {
        return li.querySelector('a')
    },
    isItemCollapsed(li) {
        var a = this.getItemLink(li)

        var collapsed = a.classList.contains('collapsed')
        return collapsed
    },
    /**
     *
     * @param li
     * @returns {el | null}
     */
    getProximoLi(li) {
        var proxLi = queryNext(li, ':not(.d-none)')
        var nextLi = null

        if (!proxLi) {

            var parentLi = this.getLiPai(li)

            if (parentLi !== null) {
                nextLi = this.getProximoLi(parentLi)
            }

        } else {
            nextLi = proxLi
        }

        return nextLi
    },
    deuMatch(str, filtro) {
        var match = false
        var filtroProcessado = menu.normalizar(filtro)
        var termoBuscaItemMenuNormalizado = menu.normalizar(str)

        if (termoBuscaItemMenuNormalizado.indexOf(filtroProcessado) !== -1 || !filtroProcessado) {
            match = true
        }

        return match
    },
    fechar() {
        document.getElementById('wrapper').classList.add('toggled')
        PaginaEproc.setCookieMenuAberto('N')

        document.getElementById('sidebar-wrapper').removeEventListener('touchstart', menu.touchstartHandler)
        document.getElementById('sidebar-wrapper').removeEventListener('touchmove', menu.touchmoveHandler)
    },
    abrir() {
        document.getElementById('wrapper').classList.remove('toggled')
        PaginaEproc.setCookieMenuAberto('S')

        document.getElementById('sidebar-wrapper').addEventListener('touchstart', menu.touchstartHandler)
        document.getElementById('sidebar-wrapper').addEventListener('touchmove', menu.touchmoveHandler)
    },
    touchstartHandler: function(e) {
        clientX = e.originalEvent.touches[0].clientX
        clientY = e.originalEvent.touches[0].clientY
    },
    touchmoveHandler: function(e) {
        var deltaX, deltaY

        deltaX = e.originalEvent.changedTouches[0].clientX - clientX
        deltaY = e.originalEvent.changedTouches[0].clientY - clientY

        if ((Math.abs(deltaX) > Math.abs(deltaY) + 20) && (deltaX < 0)) {
            menu.fechar()
        }
    },
}

function contrairMenu() {
    document.getElementById('wrapper').classList.add('toggled')
}

function expandirMenu() {
    document.getElementById('wrapper').classList.remove('toggled')
}

/**
 *
 * @param e KeyboardEvent
 */
function handleKeydownItensMenu(e) {
    var aEl = e.target
    var li = aEl.parentElement
    var key = e.key
    var proximoLink = null

    switch (key) {
        case KEYS.LEFT:
            var possuiFilhos = menu.possuiFilhos(li)

            if (possuiFilhos) {
                if (!menu.isItemCollapsed(li)) {
                    var ulDosFilhos = getUlDosFilhos(li)
                    collapse(ulDosFilhos, false)
                } else {
                    var parentLi = menu.getLiPai(li)
                    if (parentLi !== null) {
                        proximoLink = menu.getItemLink(parentLi)
                    }
                }
            } else {
                var parentLi = menu.getLiPai(li)
                if (parentLi !== null) {
                    proximoLink = menu.getItemLink(parentLi)
                }
            }
            break

        case KEYS.UP:
            proximoLink = menu.getItemAntesDe(aEl)
            if (!proximoLink) {
                proximoLink = searchInput
            }
            break

        case KEYS.RIGHT:
            var possuiFilhos = menu.possuiFilhos(li)
            if (possuiFilhos) {
                if (menu.isItemCollapsed(li)) {
                    var ulDosFilhos = getUlDosFilhos(li)
                    collapse(ulDosFilhos, true)
                } else {
                    proximoLink = menu.getItemApos(aEl)
                }
            }
            break

        case KEYS.DOWN:
            proximoLink = menu.getItemApos(aEl)
            break

        case KEYS.ESC:
            focarCampoBuscaMenu()
            break
    }

    if (proximoLink) {
        proximoLink.focus()
        e.preventDefault()
    }
}

function focusNextElement() {
    //add all elements we want to include in our selection
    var focusableElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])'

    if (document.activeElement && document.activeElement.form) {
        var focussable = Array.prototype.filter.call(
            document.activeElement.form.querySelectorAll(focusableElements),
            function(element) {
                //check for visibility while always include the current activeElement
                return (
                    element.offsetWidth > 0 ||
                    element.offsetHeight > 0 ||
                    element === document.activeElement
                )
            },
        )
        var index = focussable.indexOf(document.activeElement)
        if (index > -1) {
            var nextElement = focussable[index + 1] || focussable[0]
            nextElement.focus()
        }
    }
}

export default menu