const Cookies = require('js-cookie');

const hint = {
    _timeout: undefined,
    FADE_TIME: 400,
    url: undefined,
    ids: {
        container: 'hint-container',
        closeBtn: 'hint-close',
    },
    _preFetched: false,
    _removeListeners() {
        $('#' + hint.ids.closeBtn).off('click', hint.hideInstantly);
        $('#' + hint.ids.container).off('mouseout', hint.hideSlowly);
        $('#' + hint.ids.container).off('mouseover', hint._onmouseover);
    },
    _addListeners() {
        $('#' + hint.ids.closeBtn).click(hint.hideInstantly);
        $('#' + hint.ids.container).on('mouseout', hint.hideSlowly);
        $('#' + hint.ids.container).on('mouseover', hint._onmouseover);
        hint.hideSlowly();
    },
    _hideFn: () => {
        $('#' + hint.ids.container).fadeOut(hint.FADE_TIME);
    },
    hideInstantly: () => {
        hint._removeListeners();
        hint.clearTimeout();
        $('#' + hint.ids.container).fadeOut(hint.FADE_TIME);
    },
    _onmouseover: () => {
        hint.clearTimeout();
        var spinner = hint.spinner();
        spinner.classList.remove("anim-running");
        //void spinner.offsetWidth;
    },

    hideSlowly: () => {
        hint.spinner().classList.add("anim-running");
        hint.clearTimeout();
        hint._timeout = window.setTimeout(hint._hideFn, 10000);
    },
    clearTimeout: () => {
        if (hint._timeout !== undefined) {
            clearTimeout(hint._timeout);
            hint._timeout = undefined;
        }
    },
    getAllSeen: () => {
        var dicasVistas = Cookies.getJSON('dicas_vistas');
        if (dicasVistas === undefined) {
            dicasVistas = [];
        }
        return dicasVistas;
    },
    spinner: () => {
        return $('#' + hint.ids.container).find('.pie-spinner-wrapper')[0];
    },
    markAsSeenByUser: () => {
        if (hint._preFetched) {
            var hintId = $('#' + hint.ids.container).data('hint-id');
            var dicasVistas = hint.getAllSeen();
            dicasVistas.push(hintId);
            Cookies.set('dicas_vistas', dicasVistas);
            Cookies.set('data_ultima_dica_vista', new Date().toLocaleDateString());
            hint._preFetched = false;
        }
    },
    init: (url, text, id, title) => {
        if (text && id && title) {
            hint._text = text;
            hint._id = id;
            hint._title = title;
        }

        hint._preFetched = (hint._text && hint._id && hint._title);

        hint.url = url;

        if (!PaginaEproc.isTelaPequena()) {
            hint._show();
            hint.markAsSeenByUser();
        }
    },
    async _show() {
        if (!hint._preFetched) {
            await hint._fetchAvailable();
        }

        hint._createOrUpdateContainer();
        hint._addListeners();
        hint._showHiddenHint();
    },
    _showHiddenHint() {
        $('#' + hint.ids.container).fadeIn(hint.FADE_TIME);
    },
    async _fetchAvailable() {
        const data = {idDica: hint._getLastSeenId()};
        const result = await $.post(hint.url, data);
        const newHint = result.dados[0];
        hint._text = newHint.DesMensagem;
        hint._id = newHint.IdDica;
        hint._title = newHint.Titulo;
        console.log(newHint);

    },
    _createOrUpdateContainer() {
        let exists = document.getElementById(hint.ids.container) !== null;

        if (!exists) {
            hint._createContainer();
        } else {
            hint._updateContainer();
        }
    },
    _createContainer() {
        let id = hint._id;
        let text = hint._text;
        let title = hint._title;
        $('body').append(`
                <div class="bootstrap-styles">
                    <div id="hint-container" style="display: none;" data-hint-id="${id}" class="alert shadow-sm">
                        <div class="d-flex">
                            <div class="d-flex align-items-center">
                                <div id="hint-icon" class="align-items-center">
                                    <i class="material-icons">emoji_objects</i>
                                </div>
                            </div>
                            <div class="align-self-center">
                                <div>
                                    <span id="hint-title" class="font-weight-bold">${title}</span><br>
                                    <span id="hint-text">${text}</span>
                                </div>
                            </div>
                            <div class='timer-with-button align-self-center'>
                                <div class="pie-spinner-wrapper anim-running">
                                  <div class="spinner pie"></div>
                                  <div class="filler pie"></div>
                                  <div class="mask"></div>
                                </div>                        
                                <button type="button" id="hint-close" class="close" aria-label="Fechar" style="z-index: 400;color: black;position: absolute;"><span aria-hidden="true">&times;</span></button>
                            </div>
                        </div>
                    </div>                    
                </div>`);
    },
    _updateContainer() {
        document.getElementById('hint-text').innerHTML = hint._text;
        document.getElementById('hint-title').innerHTML = hint._title;
        document.getElementById(hint.ids.container).setAttribute('data-hint-id', hint._id);
    },
    _getLastSeenId() {
        let seenHints = Cookies.getJSON('dicas_vistas');
        let lastId = null;

        if (seenHints !== undefined) {
            lastId = Math.max(...seenHints);
        }

        return lastId;
    }
};

module.exports = hint;