let accessibility = {
    init: function () {
        let isBootStrap = $("#page-content-wrapper").hasClass("bootstrap-styles");

        const PersistOn = {
            Cokkie: 'Cokkie',
            Server: 'Server',
            Both: 'Both'
        };

        class Personalizacao {
            constructor(id, ativo, numvalor) {
                this.id = id;
                this.ativo = ativo;
                this.numvalor = numvalor;
            }
        }

        //todo dni13 - cookie
        if (isBootStrap) {
            let fontSizePercentage = 100;

            //Define o tamanho da fonte conforme preferência do usuario
            let cookieFontSizePercentage = getCookie("fontSizePercentage");
            if ($("#btn-aumentar-fonte").data("ativo") === 'S' && $("#btn-aumentar-fonte").data("numvalor")){
                fontSizePercentage  = $("#btn-aumentar-fonte").data("numvalor");
            } else if (cookieFontSizePercentage !== "") {
                let ojbFontSizePercentage = JSON.parse(cookieFontSizePercentage);
                if(ojbFontSizePercentage.ativo){
                    fontSizePercentage = ojbFontSizePercentage.numvalor;
                }
            }

            applyFontSize(fontSizePercentage);

            function aumentarFonte(element){
                fontSizePercentage += 10;
                applyFontSize(fontSizePercentage);
                salvarPreferenciaAcessibilidade("fontSizePercentage", element.data("id"), true,fontSizePercentage,PersistOn.Both);
            }

            function diminuirFonte(element){
                fontSizePercentage -= 10;
                applyFontSize(fontSizePercentage);
                salvarPreferenciaAcessibilidade("fontSizePercentage", element.data("id"), true,fontSizePercentage,PersistOn.Both);
            }

            $("#btn-aumentar-fonte").click(function () {
                aumentarFonte($(this));
            });

            $("#btn-diminuir-fonte").click(function () {
                diminuirFonte($(this));
            });

        } else {
            let fontZoomScale = 1;

            //Define o tamanho da fonte conforme preferência do usuario
            let cookieFontZoomScale = getCookie("fontSizePercentage");
            if ($("#btn-aumentar-zoom").data("ativo") === 'S' && $("#btn-aumentar-zoom").data("numvalor")){
                fontZoomScale  = fontSizeToFontZoom(parseInt($("#btn-aumentar-zoom").data("numvalor")));
            } else if (cookieFontZoomScale !== "") {
                let ojbFontZoomScale = JSON.parse(cookieFontZoomScale);
                if(ojbFontZoomScale.ativo){
                    fontZoomScale = fontSizeToFontZoom(parseInt(ojbFontZoomScale.numvalor));
                }
            }

            applyFontSize(fontZoomScale);

            function aumentarFonte(element){
                fontZoomScale += .1;
                applyFontSize(fontZoomScale);
                salvarPreferenciaAcessibilidade("fontSizePercentage", element.data("id"), true,fontZoomToFontSize(fontZoomScale),PersistOn.Both);
            }

            function diminuirFonte(element){
                fontZoomScale -= .1;
                applyFontSize(fontZoomScale);
                salvarPreferenciaAcessibilidade("fontSizePercentage", element.data("id"), true,fontZoomToFontSize(fontZoomScale),PersistOn.Both);
            }

            $("#btn-aumentar-zoom").click(function () {
                aumentarFonte($(this));
            });

            $("#btn-diminuir-zoom").click(function () {
                diminuirFonte($(this));
            });
        }

        function expandirMenu() {
            $('#wrapper').removeClass('toggled');
        }
        $("#ancora-menu").click(function () {
            expandirMenu()
        });


       if ($('#navbar-accessibility').data('ativo') === 'N') {
            $('#navbar-accessibility').collapse('hide');
       } else {
            $('#navbar-accessibility').collapse('show');
       }

        //Persiste a preferência do usuario ao exibir a barra de acessibilidade
       $('#navbar-accessibility').on('show.bs.collapse', function () {
            salvarPreferenciaAcessibilidade("barra-acessibilidade", $(this).data("id"), true,null,PersistOn.Server);
       });

       //Persiste a preferência do usuario ao ocultar a barra de acessibilidade
       $('#navbar-accessibility').on('hide.bs.collapse', function () {
           salvarPreferenciaAcessibilidade("barra-acessibilidade", $(this).data("id"), false,null,PersistOn.Server);
       });

       //CheckBox - Barra de Acessibilidade - Tela de Configurações do usuário
       $('#ch69').change(function(){
           if ($(this).is(':checked')){
               $('#navbar-accessibility').collapse('show');
           } else {
               $('#navbar-accessibility').collapse('hide');
           }
       });

       //Define o alto contraste conforme preferência do usuário
       let cookieThemeContrast = getCookie("theme-contrast");
       if (cookieThemeContrast !== "") {
           let objThemeContrast = getJSON(cookieThemeContrast);
           if (objThemeContrast.ativo || $("#btn-theme-contrast").data('ativo') === 'S') {
               $("body").addClass("theme-contrast");
           }
       } else {
           if ($("#btn-theme-contrast").data('ativo') === 'S') {
               $("body").addClass("theme-contrast");
           }
       }

       $("#btn-theme-contrast").click(function (e) {
           $("body").toggleClass("theme-contrast");
           salvarPreferenciaAcessibilidade("theme-contrast",$(this).data("id"),$("body").hasClass("theme-contrast"),null,PersistOn.Both);
       });

       //CheckBox - Alto Contraste - Tela de Configurações do usuário
       $('#ch68').change(function(){
           $("body").toggleClass("theme-contrast");
           salvarPreferenciaAcessibilidade("theme-contrast",$(this).attr("name"),$("body").hasClass("theme-contrast"),null,PersistOn.Both);
       });

       //CheckBox - Tamanho da fonte - Tela de Configurações do usuário
       $('#ch70').change(function(){
           if ($(this).is(':checked')){
               applyFontSize( isBootStrap ? $('#numIdTamanhoFonte').val() : fontSizeToFontZoom($('#numIdTamanhoFonte').val()));
           } else {
              applyFontSize();
           }
       });
       //Input - Tamanho da fonte - Tela de Configurações do usuário
       $('#numIdTamanhoFonte').on('input',function(e){
            applyFontSize(isBootStrap ? $(this).val() : fontSizeToFontZoom($(this).val()));
       });

       function applyFontSize(fontSize = 100){
           if (isBootStrap) {
               $('html').css('font-size', fontSize + '%');
               setBtnTitle(fontSize);
           } else {
              $('html').css('zoom', fontSize);
              setBtnTitle(fontSize);
           }
       }

        function setBtnTitle(fontSize = 100){
            if (isBootStrap) {
                $('#btn-aumentar-fonte').prop('title', 'Aumentar fonte (' + fontSize + '%)');
                $('#btn-diminuir-fonte').prop('title', 'Diminuir fonte (' + fontSize + '%)');
            } else {
                $('#btn-aumentar-zoom').prop('title', 'Aumentar zoom (' + fontZoomToFontSize(fontSize) + '%)');
                $('#btn-diminuir-zoom').prop('title', 'Diminuir zoom (' + fontZoomToFontSize(fontSize) + '%)');
            }
        }

        function fontZoomToFontSize(fontZoom) {
           return Math.round((fontZoom * 100));
        }

        function fontSizeToFontZoom(fontSize){
           return (parseInt(fontSize) / 100);
        }

       $("#btn-vlibras").click(function (e) {
           $("#vlibras-container").toggleClass("enabled");
           if ($("#vlibras-container").hasClass("enabled")) {
              $("#vlibras-access-button").click();
           }
       });

       function getJSON(input) {
           try {
               return JSON.parse(input);
           } catch (error) {
               return {};
           }
       }

       function salvarPreferenciaAcessibilidade(cname, id,ativo, numvalor, persistOn) {
           let personalizacao = new Personalizacao(id, ativo, numvalor);
           if (persistOn === PersistOn.Cokkie) {
               setCookie(cname, JSON.stringify(personalizacao), 365);
           } else if (persistOn === PersistOn.Server) {
               $.post($("#link-usuario-configuracao-personalizacao").val(), personalizacao) ;
           } else if (persistOn === PersistOn.Both) {
               setCookie(cname, JSON.stringify(personalizacao), 365);
               $.post($("#link-usuario-configuracao-personalizacao").val(), personalizacao);
           } else {
               setCookie(cname, JSON.stringify(personalizacao), 365);
           }
       }

        //teclas de atalho
        function abrirListaTeclasAtalho() {
            let href = $("#btn-pagina-acessibilidade").attr('href');
            window.location.href = href;
        }

        $(document).bind('keydown','Alt+f1',abrirListaTeclasAtalho);

        function irParaConteudo() {
            $('#navbar-accessibility').collapse('show');
            $('#ancora-conteudo').focus();
        }
        $(document).bind('keydown','Alt+1',irParaConteudo);

        function setCookie(cname, cvalue, exdays) {
            const d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            let expires = "expires="+d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        }

        function getCookie(cname) {
            let name = cname + "=";
            let ca = document.cookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
    }
};

module.exports = accessibility;